const Navbar = () => {
    return (
        <div id="nav-bar" className="navbar text-base-content bg-base-200">
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost btn-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                    </label>
                    <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                        <li><a href="/services">Service</a></li>
                    </ul>
                </div>
            </div>
            <div className="navbar-center">
                <a href="/" id="nav-logo" className="btn btn-ghost normal-case text-2xl">King Nail & Spa</a>
            </div>
            <div className="navbar-end">
                <button href className="btn btn-ghost btn-circle">
                    <a href="https://g.page/r/CQDYclUCwvR8EB0/review" target="blank"><i className="fa-solid fa-address-card fa-bounce text-xl"></i></a>
                </button>
            </div>
        </div>
    )
};

export default Navbar;