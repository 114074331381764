import axios from "axios";
import { useState } from "react";
import { toast } from "react-hot-toast";

const Footer = () => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isNaN(phoneNumber) || phoneNumber.length !== 10) {
            toast.error('Phone number invalid!');
            return;
        }
        try {
            const response = await axios.post('https://king-nail-spa-db.onrender.com/api/send_text', {
                phone_number: phoneNumber
            });
            toast.success('Phone number successfully submitted!');
            setPhoneNumber('');
        } catch (error) {
            console.error(error);
            toast.error('Failed to submit phone number!');
        }
    }


    return (
        <>
            <footer className="footer p-10 bg-base-300 text-base-content">
                <div>
                    <img className="w-44" src="./images/logo.png" alt="Logo" />
                    <span>
                        <a href="https://www.facebook.com/Aubrunkingnailspa/" target="blank"><i className="fa-brands fa-facebook text-xl p-4"></i></a>
                        <a href="https://g.co/kgs/ijNR4K" target="blank"><i className="fa-brands fa-google text-xl"></i></a>
                        <a href="https://github.com/Brucetran323" target="blank"><i className="fa-brands fa-github text-xl p-4"></i></a>
                        <a href="/#nav-bar"> <i className="fa-solid fa-up-long fa-bounce pt-3 text-xl"></i></a>
                    </span>
                </div>
                <div>
                    <span className="footer-title"><i className="fa-solid fa-circle-xmark"></i> We do not accept</span>
                    <div className="text-2xl">
                        <p><i class="fa-brands fa-cc-amex"></i> <i class="fa-solid fa-mobile-screen-button"></i> <i class="fa-brands fa-apple-pay"></i> <i class="fa-brands fa-google-pay"></i></p>
                    </div>
                    <br />
                    <span className="footer-title"><i className="fa-solid fa-circle-check"></i> We do accept</span>
                    <div className="text-2xl">
                        <p><i className="fa-solid fa-money-bill"></i> <i className="fa-brands fa-cc-visa"></i> <i className="fa-brands fa-cc-mastercard"></i> <i className="fa-brands fa-cc-discover"></i></p>
                    </div>
                    <br />
                    <a href="/services"><span className=" link link-hover footer-title"><i className="fa-solid fa-hand-holding-droplet"></i> Services</span></a>
                    <a href="https://g.page/r/CQDYclUCwvR8EB0/review" target="blank"><span className=" link link-hover footer-title"><i className="fa-brands fa-google"></i> Review us on google!</span></a>
                </div>
                <div>
                    <span className="footer-title"><i className="fa-solid fa-location-dot"></i> Location</span>
                    <a href="https://www.google.com/maps?ll=34.010714,-83.823059&z=15&t=m&hl=en-US&gl=US&mapclient=embed&cid=9004034870299777024" target="blank">
                        <h3>1296 Atlanta Hwy,</h3>
                        <h3>Auburn, GA 30011</h3>
                    </a>

                    <br />
                    <span className="footer-title"><i className="fa-solid fa-clock"></i> Hours</span>
                    <h3>Wednesday-Saturday: 10am-7pm</h3>
                    <h3>Sunday: 12pm-6pm</h3>
                    <br />
                    <span className="footer-title"><i className="fa-solid fa-mobile-screen-button"></i> Call Us</span>
                    <a href="tel:470-246-5812">(470) 246-5812</a>
                </div>
                <div>
                    <iframe id="footer-map" width="350" height="200" referrerPolicy="no-referrer-when-downgrade"
                        src={process.env.REACT_APP_GOOGLE_MAP}
                        allowFullScreen>
                    </iframe>
                </div>
                <div>
                    <span className="footer-title">Text Announcements</span>
                    <form onSubmit={handleSubmit} className="form-control w-80">
                        <label className="label">
                            <span className="label-text">Enter your phone number</span>
                        </label>
                        <div className="relative">
                            <input type="number" inputMode="numeric" placeholder="Numbers Only!" className="input input-bordered w-full pr-16" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                            <button type="submit" className="btn btn-secondary absolute top-0 right-0 rounded-l-none text-white">Text Me!</button>
                        </div>
                        <label className="label">
                            <span className="label-text">Your carrier may charge for SMS messages</span>
                        </label>
                    </form>
                </div>

            </footer>
        </>
    )
};

export default Footer;