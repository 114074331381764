import axios from "axios";
import { useState } from "react";

const OurServices = () => {
    
    const services_api = "https://king-nail-spa-db.onrender.com/api/all"
    const getServAPI = async () => {
        let response = await axios.get(services_api);
        return response.status === 200 ? response.data : null;
    };

    const loadServAPI = async () => {
        let data = await getServAPI();
        setServices(data.data.categories);
    };

    const [services, setServices] = useState(() => loadServAPI());
    let mani = services.manicures
    let pedi = services.pedicures
    let art = services.artificial
    let add = services.additional
    let wax = services.waxes



    return (
        <>
            <div id="serv-page" className="text-center">
                <div id="services-hero" className="hero min-w-screen min-h-screen bg-base-200">
                    <div className="hero-content flex-col lg:flex-row-reverse">
                    </div>
                    <div className="text-center font-bold">
                        <h1 id="hero-title" className="text-7xl font-bold">Services</h1>
                        <p className="py-5">
                            <a href="#mani">
                                Manicures|   |
                            </a>
                            <a href="#pedi">
                                Pedicures
                            </a>
                            <br />
                            <a href="#art">
                                Artificial|  |
                            </a>
                            <a href="#add">
                                Additional Services|  |
                            </a>
                            <a href="#wax">
                                Waxing Services
                            </a>
                        </p>
                    </div>
                </div>

                <div className="h-16"></div>
                <div>
                    <div id="mani" className="card w-2/4 lg:card-side bg-base-100 shadow-xl mx-auto ">
                        <div className="card-body">
                            <img id="serv_card_img" src="./images/serv/serv_mani.jpg" alt="Manicure" />
                            <h2 id="serv-title" className="text-center font-bold text-4xl pt-2">Manicures</h2>
                            <hr />
                            {typeof mani === 'object' && !mani.then ? mani.map((m, index) => {
                                return (
                                    <div key={index}>
                                        <h1 className="text-lg pt-4"><u>{m.title}</u></h1>
                                        <h3 className="text-lg pt-2">{m.description}</h3>
                                        <br />
                                        <h4 className="font-bold text-lg">{m.price}</h4>
                                        <br />
                                        <hr />
                                    </div>
                                );
                            }) : (
                                <img className="w-9 mx-auto" src="https://i.gifer.com/ZKZg.gif" alt="loading" />
                            )}
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                        </div>
                    </div>

                    <div className="h-16"></div>

                    <div id="pedi" className="card w-2/4 lg:card-side bg-base-100 shadow-xl mx-auto">
                        <div className="card-body">
                            <img id="serv_card_img" src="./images/serv/serv_pedi.jpg" alt="Pedicure" />
                            <h2 id="serv-title" className="text-center font-bold text-4xl pt-2">Pedicure</h2>
                            <hr />
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                            {typeof pedi === 'object' && !pedi.then ? pedi.map((p, index) => {
                                return (
                                    <div key={index}>
                                        <h1 className="text-lg pt-4"><u>{p.title}</u></h1>
                                        <h3 className="text-lg pt-2">{p.description}</h3>
                                        <br />
                                        <h4 className="font-bold text-lg">{p.price}</h4>
                                        <br />
                                        <hr />
                                    </div>
                                );
                            }) : (
                                <img className="w-9 mx-auto" src="https://i.gifer.com/ZKZg.gif" alt="loading" />
                            )}
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                        </div>
                    </div>

                    <div className="h-16"></div>

                    <div id="art" className="card w-2/4 lg:card-side bg-base-100 shadow-xl mx-auto">
                        <div className="card-body">
                            <img id="serv_card_img" src="./images/serv/serv_art.jpg" alt="Artifictal Nails" />
                            <h2 id="serv-title" className="text-center font-bold text-4xl pt-2">Artificial</h2>
                            <hr />
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                            {typeof art === 'object' && !art.then ? art.map((a, index) => {
                                return (
                                    <div key={index}>
                                        <h1 className="text-lg pt-4"><u>{a.title}</u></h1>
                                        <br />
                                        <h4 className="font-bold text-lg">{a.price}</h4>
                                        <br />
                                        <hr />
                                    </div>
                                );
                            }) : (
                                <img className="w-9 mx-auto" src="https://i.gifer.com/ZKZg.gif" alt="loading" />
                            )}
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                        </div>
                    </div>

                    <div className="h-16"></div>

                    <div id="add" className="card w-2/4 lg:card-side bg-base-100 shadow-xl mx-auto">
                        <div className="card-body">
                            <img id="serv_card_img" src="./images/serv/serv_add.jpg" alt="Additional Services" />
                            <h2 id="serv-title" className="text-center font-bold text-4xl pt-2">Additonal Services</h2>
                            <hr />
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                            {typeof add === 'object' && !add.then ? add.map((a, index) => {
                                return (
                                    <div key={index}>
                                        <h1 className="text-lg pt-4"><u>{a.title}</u></h1>
                                        <br />
                                        <h4 className="font-bold text-lg">{a.price}</h4>
                                        <br />
                                        <hr />
                                    </div>
                                );
                            }) : (
                                <img className="w-9 mx-auto" src="https://i.gifer.com/ZKZg.gif" alt="loading" />
                            )}
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                        </div>
                    </div>

                    <div className="h-16"></div>

                    <div id="wax" className="card w-2/4 lg:card-side bg-base-100 shadow-xl mx-auto">
                        <div className="card-body">
                            <img id="serv_card_img" src="./images/serv/serv_wax.jpg" alt="Wax" />
                            <h2 id="serv-title" className="text-center font-bold text-4xl pt-2">Waxing Services</h2>
                            <hr />
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                            {typeof wax === 'object' && !wax.then ? wax.map((w, index) => {
                                return (
                                    <div key={index}>
                                        <h1 className="text-lg pt-4"><u>{w.title}</u></h1>
                                        <br />
                                        <h4 className="font-bold text-lg">{w.price}</h4>
                                        <br />
                                        <hr />
                                    </div>
                                );
                            }) : (
                                <img className="w-9 mx-auto" src="https://i.gifer.com/ZKZg.gif" alt="loading" />
                            )}
                            <a href="#services-hero"> <i className="fa-solid fa-up-long fa-bounce pt-3"></i></a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OurServices