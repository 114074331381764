// import axios from "axios";
import { useEffect, useState } from "react";
import Modal from "../components/Modal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';



const Home = () => {

    // const getAPI = async () => {
    //     let response = await axios.get(process.env.REACT_APP_NEWEST_GOOGLE_REVIEW);
    //     return response.status === 200 ? response.data : null;
    // };

    // const loadAPI = async () => {
    //     let data = await getAPI();
    //     setApi(data.result);
    // };

    // const [api, setApi] = useState(() => loadAPI());
    // const reviews = api.reviews

    const [openModal, setOpenModal] = useState(false);
    useEffect(() => {
        const timerId = setTimeout(() => {
            setOpenModal(true);
        }, 3000);

        return () => {
            clearTimeout(timerId);
        };
    }, []);
    return (
        <>
            <div id="hero" className="hero min-w-screen bg-base-200">
                <div className="hero-content flex-col lg:flex-row-reverse">
                </div>
                <div id="hero-text" className="text-center">
                    <h1 id="hero-title" className="text-7xl font-bold">King Nail & Spa</h1>
                    <a href="https://www.google.com/maps?ll=34.010714,-83.823059&z=15&t=m&hl=en-US&gl=US&mapclient=embed&cid=9004034870299777024" target="blank">
                        <h3 className="py-4 text-2xl font-bold">1296 Atlanta Hwy, <br /> Auburn, GA 30011</h3>
                    </a>
                    <h3 className="text-xl">Wednesday-Saturday: 10am-7pm</h3>
                    <h3 className="text-xl">Sunday: 12pm-6pm</h3>
                    <p className="py-4 text-xl font-semibold"><a href="tel:470-246-5812">(470) 246-5812</a></p>
                    <Modal open={openModal} onClose={() => setOpenModal(false)} />
                    <a href="/services"><button className="btn btn-base-content">See our services!</button></a>
                </div>
            </div>

            <div className="h-16"></div>

            <h1 id="hero-title" className="text-center text-4xl font-bold">Our Reviews</h1>

            <div id="spacer" className="h-16"></div>

            <div className="flex flex-wrap justify-around w-full">

                <div id="review-card" className="card bg-base-100 shadow-xl max-w-sm mt-5">
                    <figure className="px-10 pt-10">
                        <a href="https://www.google.com/maps/contrib/108144870574073430475/reviews/@30.0203412,-82.2121482,6z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US"
                            target="blank">
                            <img src="https://lh3.googleusercontent.com/a-/ACB-R5RJ9yg7avCZupcE8vtyC078APExDTPWEI1wPsoTzX4=w75-h75-p-rp-mo-br100"
                                alt="Avatar" className="rounded-xl w-28" />
                        </a>
                    </figure>

                    <div className="card-body items-center text-center">
                        <h2 className="card-title">Natalia Montilla</h2>
                        <div className="rating gap-1">
                            <input type="radio" name="rating-3" className="mask mask-heart bg-red-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-orange-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-yellow-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-lime-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-green-400" />
                        </div>
                        <p>This is probably THE BEST nail salon I've ever been to! Service is absolutely exceptional.
                            The owners are kind and polite. They are considerate with every aspect of you nail care and
                            do it all with a smile on their face. I barely used to get my nails done but the experience has
                            me going back every month.</p>
                    </div>
                </div>
                <div id="review-card" className="card bg-base-100 shadow-xl max-w-sm mt-5">
                    <figure className="px-10 pt-10">
                        <a href="https://www.google.com/maps/contrib/107718435526918843652/reviews/@33.7591732,-110.4257947,5z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US"
                            target="blank">
                            <img src="https://lh3.googleusercontent.com/a-/ACB-R5R_9VWWfDKxfbaW52akaoXyycseEPDJ8mUSlGMyyQ=w75-h75-p-rp-mo-br100"
                                alt="Avatar" className="rounded-xl w-28" /></a>
                    </figure>

                    <div className="card-body items-center text-center">
                        <h2 className="card-title">Jennifer Buffard</h2>
                        <div className="rating gap-1">
                            <input type="radio" name="rating-3" className="mask mask-heart bg-red-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-orange-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-yellow-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-lime-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-green-400" />
                        </div>
                        <p>The gentleman that worked there did such a great job and was very detailed and made sure that he gave me a
                            deluxe pedicure and for the price I have never had a better pedicure. I would definitely go back. Great
                            customer service and takes great care of his clients</p>
                    </div>
                </div>
                <div id="review-card" className="card bg-base-100 shadow-xl max-w-sm mt-5">
                    <figure className="px-10 pt-10">
                        <a href="https://www.google.com/maps/contrib/110894913073881974185/reviews/@33.8786902,-84.2470992,11z/data=!4m3!8m2!3m1!1e1?hl=en-US"
                            target="blank">
                            <img src="https://lh3.googleusercontent.com/a-/ACB-R5Q_7vitMyxpQ80xjYZqBAPiN2MpGm9MVvVqJuVN=w75-h75-p-rp-mo-br100"
                                alt="Avatar" className="rounded-xl w-28" /></a>
                    </figure>

                    <div className="card-body items-center text-center">
                        <h2 className="card-title">Meraki Soul Catering</h2>
                        <div className="rating gap-1">
                            <input type="radio" name="rating-3" className="mask mask-heart bg-red-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-orange-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-yellow-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-lime-400" />
                            <input type="radio" name="rating-3" className="mask mask-heart bg-green-400" />
                        </div>
                        <p>I can’t tell you how great a place this is!  Such sweet and caring owners and we were treated and felt
                            like family when we were there. We were offered beverages, received hot towels and hot stones during
                            our pedicures. The manicures were also amazing!  You have to come here, you will be very happy 🙏🏽❤️</p>
                    </div>
                </div>

            </div>


            <div className="h-16"></div>

            <h1 id="hero-title" className=" text-center text-4xl font-bold">Services</h1>

            <div id="spacer" className="h-16"></div>

            <div id="serv-cards" className="inline-grid grid-cols-3 gap-4 p-3">
                <a href="/services">
                    <div id="serv-container">
                        <img id="serv-img" src="./images/mani.jpg" alt="Manicure" style={{ width: "100%", height: "100%" }} />
                        <div id="serv-overlay">
                            <div id="serv-text">
                                <h2 className="text-center text-4xl font-bold">Manicure</h2>
                                <p className="py-5">Get flawless nails with our manicure! Let us pamper your hands with expert care and choose from a variety of colors, & designs</p>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="/services">
                    <div id="serv-container">
                        <img id="serv-img" src="./images/pedi.jpg" alt="Pedicure" style={{ width: "100%", height: "100%" }} />
                        <div id="serv-overlay">
                            <div id="serv-text">
                                <h2 className="text-center text-4xl font-bold">Pedicure</h2>
                                <p className="py-5">Transform your feet with our pedicure! Let us clean, shape, & polish your toenails, while also exfoliating & moisturizing your feet</p>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="/services">
                    <div id="serv-container">
                        <img id="serv-img" src="./images/eye.jpg" alt="Eyebrows" style={{ width: "100%", height: "100%" }} />
                        <div id="serv-overlay">
                            <div id="serv-text">
                                <h2 className="text-center text-4xl font-bold">Eyebrow Services</h2>
                                <p className="py-5">Get flawless brows with our expert services! Our skilled technicians will shape, & trim your eyebrows to enhance your natural beauty</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <div className="h-16"></div>

            <div className="hero bg-base-200">
                <div className="hero-content flex-col lg:flex-row">
                    <img id="home-about-img" src="./images/logo.png" alt="Logo" className="max-w-sm rounded-lg " />
                    <div id="home-about-title">
                        <h1 id="hero-title" className="text-5xl font-bold">About us</h1>
                        <p className="py-6">At King Nail and Spa, we believe in providing our clients with the highest level of care and pampering.
                            Our luxurious spa offers a wide range of services including manicures, pedicures, facials, waxing, and more.
                            We use only the highest quality products and the latest techniques to ensure that our clients receive the best experience possible.
                            Our highly trained and friendly staff are dedicated to making every visit to our spa a relaxing and rejuvenating one.
                            Come experience the ultimate in luxury and leave feeling like royalty at King Nail and Spa!</p>
                    </div>
                </div>
            </div>

            <div className="hero min-h-screen">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <div className="lg:w-1/2">
                        <Carousel showThumbs={false} infiniteLoop={true}>
                            <div>
                                <img src="./images/nails/1.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/nails/2.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/nails/3.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/nails/4.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/nails/5.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/nails/6.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/nails/7.jpg" alt="Nail Salon Images" />
                            </div>
                        </Carousel>
                    </div>


                    <div className="text-center lg:text-left lg:w-1/2">
                        <h1 id="hero-title" className="text-5xl font-bold">Our Art</h1>
                        <p className="py-6 text-lg">Experience nail art like never before. Step into our salon and discover a world where trendsetting designs meet sheer beauty.
                            Our images tell the story of innovation and style – from vibrant hues to intricate details, we create nails that are true masterpieces.
                            Indulge in the fusion of art and self-care. Our technicians aren't just professionals; they're artists crafting beauty at your fingertips.
                        </p>
                    </div>
                </div>
            </div>
            <div className="hero min-h-screen bg-base-200">
                <div className="hero-content flex-col lg:flex-row">
                    <div className="lg:w-3/4">
                        <Carousel showThumbs={false} infiniteLoop={true}>
                            <div>
                                <img src="./images/store/2.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/store/3.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/store/4.jpg" alt="Nail Salon Images" />
                            </div>
                            <div>
                                <img src="./images/store/5.jpg" alt="Nail Salon Images" />
                            </div>
                        </Carousel>
                    </div>


                    <div className="text-center lg:text-left lg:w-1/2">
                        <h1 id="hero-title" className="text-5xl font-bold">Our Salon</h1>
                        <p className="py-6 text-lg">
                            Our salon provides a relaxing and welcoming atmosphere for childern, adults and the elderly. Discover nail services that combine creativity with 
                            meticulous cleanliness. Our devotion to your well-being reflects in every aspect of our salon. Come, be a part of a nail journey that's not only 
                            about aesthetics but also about providing a clean and inviting space for all.
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
};

export default Home;