import React from 'react';
import axios from "axios";
import { useState } from "react";
import { toast } from "react-hot-toast";

const Modal = ({ open, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isNaN(phoneNumber) || phoneNumber.length !== 10) {
      onClose();
      toast.error('Phone number invalid!');
      return;
    }
    try {
      const response = await axios.post('https://king-nail-spa-db.onrender.com/api/send_text', {
        phone_number: phoneNumber
      });
      toast.success('Phone number successfully submitted!');
      onClose();
      setPhoneNumber('');
    } catch (error) {
      console.error(error);
      onClose();
      toast.error('Failed to submit phone number!');
    }
  }

  if (!open) return null;
  return (
    <div id='overlay' className={open ? 'show' : ''} onClick={onClose}>
      <div onClick={(e) => { e.stopPropagation(); }} className={`modalContainer ${open ? 'show' : ''}`}>
        <img className='p-5' id='mod_img' src="./images/logo.png" alt='/' />
        <div className='modalRight'>
          <button id='closeBtn' onClick={onClose} className='pr-3 font-bold text-3xl'>X</button>
          <div className='content'>
            <h1 className='font-bold text-3xl'>Welcome to King Nail & Spa!</h1>
            <div className='pt-3'>
              <span className="footer-title font-bold text-secondart">Text Announcements</span>
              <form id="modal-form" onSubmit={handleSubmit} className="form-control w-80">
                <label className="label">
                  <span className="label-text">Enter your phone number</span>
                </label>
                <div className="relative">
                  <input type="number" inputMode="numeric" placeholder="Numbers Only!" className="input input-bordered w-full pr-16" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                  <button type="submit" className="btn btn-secondary absolute top-0 right-0 rounded-l-none text-white">Text Me!</button>
                </div>
                <label className="label">
                  <span className="label-text">Your carrier may charge for SMS messages</span>
                </label>
              </form>
            </div>
            <a href="https://g.page/r/CQDYclUCwvR8EB0/review" target='blank'><button className="btn btn-secondary m-3 text-white">Submit a Google Review!</button></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;